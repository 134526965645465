<template>
	<v-row align="center" justify="center">
		<v-col cols="12" sm="8" md="7" lg="6" xl="5">
			<base-material-card
				:icon="icon"
				:color="error ? 'error' : 'success'"
				v-bind="$attrs"
				v-on="$listeners"
			>
				<template v-slot:after-heading>
					<div align="center" class="ml-auto text-right">
						<h1 class="font-weight-light text--primary">
							Verificación - Correo electrónico
						</h1>
					</div>
				</template>

				<v-col
					cols="12"
					class="px-0"
				>
					<v-divider />
				</v-col>

				<v-card-text align="center">
					<h3 class="font-weight-light" :class="{'red--text': error}">
							<b>{{ message }}</b>
					</h3>
				</v-card-text>

				<template v-slot:actions>
					<v-spacer></v-spacer>
					<v-btn
						v-if="loginButton"
						color="primary"
						:to="{ name: 'Login' }"
					>
						Ir al login
					</v-btn>
					<v-btn
						v-if="sendNewToken"
						color="primary"
						:to="{ name: 'SendVerificationEmail' }"
					>
						Enviar Nuevo Correo
					</v-btn>
    		</template>
			</base-material-card>
		</v-col>

		<base-loader />
	</v-row>
</template>

<script>
import { mapGetters } from 'vuex'
// Mixins
import MainExternalData from '@/mixins/main-external-data'

export default {
	mixins: [MainExternalData],

	data() {
		return {
			error: false,
			message: 'Estamos verificando los datos enviados...',
			loginButton: false,
			sendNewToken: false,
		}
	},

	computed: {
		...mapGetters(['activeSession']),
		icon(){
			return this.error ? 'mdi-alert-circle' : 'mdi-check-circle';
		}
	},

	created() {
		if (this.activeSession) {
			this.$router.push({ name: 'Home' });
		}
		this.verifyToken()
	},

	methods: {
		async verifyToken() {
			let me=this;

			if (!me.$route.params.token) {
				me.error=true
				me.message='No se encontró ningún token de verificación.'
				me.sendNewToken=true
				return
			}

			try {
        me.SHOW_LOADING()
				let request={
					'token': me.$route.params.token,
				};
				await me.getBaseResponseWithoutHeaders('api/Account/VerifyEmail', request, true).then(data => {
					if (data.success) {
						me.message='Se verificó el correo electrónico satisfactoriamente. Presione el botón que se encuentra debajo para ir al login.'
						me.loginButton=true
					} else {
						me.error=true
						me.message=data.message
						if (data.code === '226') {
							me.sendNewToken=true
						}
					}
				}).catch(function(error){
					me.$swal(me.swalConfig.errorTitle, error.message, 'error');
				});
			} catch (error) {
        me.catchError(error)
      } finally {
        me.HIDE_LOADING()
      }
    },
	},
}
</script>